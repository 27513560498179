.team-item {
  height: 82px;
  padding: 0 32px;
  background-color: #892cd00f;
  border-radius: 32px;
  .count {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--color-accent);
  }
  .title {
    margin-left: 16px;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-main);
  }
}
