.MuiDayCalendar-weekDayLabel {
  color: var(--color-accent);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  min-width: 40px;
  margin: 0 8px;
  margin-top: 15px;
  margin-bottom: 3px;
}

.MuiPickersDay-root {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-width: 40px;
  min-height: 40px;
  margin: 0 8px;
  margin-top: 2px;
  margin-bottom: 3px;
}

.MuiPickersDay-dayOutsideMonth {
  color: #8a8a8a;
  font-weight: 500;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--color-accent) !important;
  &:hover {
    background-color: var(--color-accent) !important;
  }
}

.MuiPickersArrowSwitcher-button {
  padding: 0;
  margin-left: 8px;
}

.MuiPickersCalendarHeader-root {
  padding-left: 10px;
  padding-right: 5px;
}

.MuiDayCalendar-slideTransition {
  min-height: 285px;
}

.MuiDateCalendar-viewTransitionContainer {
  width: 400px;
}

.MuiDateCalendar-root {
  width: auto;
  max-height: fit-content;
}

.MuiTableCell-root {
  border-bottom: 1px solid #ebebeb;
}

.MuiOutlinedInput-root {
  border-radius: 14px;
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #0a0f1a75;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-accent);
  border-width: 1px;
}

.MuiOutlinedInput-input {
  padding: 9px 15px;
  color: var(--color-main);
  font-size: 13px;
  line-height: 20px;
  &::placeholder {
    opacity: 0.46;
  }
}

.MuiDialog-root {
  padding-left: 250px;
  .MuiDialog-paper {
    max-width: 800px;
  }
}

.MuiPopover-paper {
  border-radius: 16px;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: var(--color-accent);
  color: white;
  &:hover {
    background-color: var(--color-accent);
  }
}
