.name__wrapper {
  background-color: #2c2ed00d;
  min-width: 164px;
  width: fit-content;
  max-width: 250px;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 14px;
  .dot {
    min-width: 8px;
    min-height: 8px;
    border-radius: 4px;
    background-color: var(--color-accent);
  }
  .name {
    font-size: 13px;
    color: var(--color-accent);
    margin-left: 10px;
  }
}

.date {
  font-size: 13px;
  font-weight: 500;
  color: var(--color-accent);
}

.action {
  font-size: 13px;
  color: var(--color-accent);
}
