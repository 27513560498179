@import "/src/animation.scss";

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 19px;
}

.name {
  font-size: 15px;
  line-height: 18px;
  color: var(--color-main);
}

.active {
  .name {
    animation: ColorHighLight 1s linear;
    -webkit-animation: ColorHighLight 1s ease-in-out;
    font-weight: 800;
    color: var(--color-accent);
  }
}

.ad-hoc-rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 13px;
  height: 28px;
  width: fit-content;
  color: var(--color-accent);
  border-radius: 14px;
  border: 1px solid var(--color-accent);
  white-space: nowrap;
}

.description {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}

.date {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.english {
  height: 28px;
  min-width: 123px;
  background-color: #2c2ed00d;
  border-radius: 14px;
  width: fit-content;
  .dot {
    margin-left: 20px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--color-accent);
  }
  .english-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-accent);
    margin: 0 20px 0 10px;
  }
}

.last-step {
  min-width: 138px;
  width: fit-content;
  height: 35px;
  background-color: #0b9607;
  border-radius: 14px;
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 0 25px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.next-step-final {
  width: fit-content;
  height: 35px;
  background-color: #0b9607;
  border-radius: 14px;
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 0 8px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.next-step {
  min-width: 138px;
  width: fit-content;
  height: 35px;
  border-radius: 14px;
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(145, 145, 145);
}

.next-step-action {
  color: white;
  background-color: var(--color-accent);
  border: none;
}

.next-step-cancel {
  color: white;
  background-color: #f91313;
  border: none;
  margin-left: 10px;
}
