.avatar {
  width: 38px;
  height: 38px;
  border-radius: 19px;
}

.name {
  font-size: 15px;
  line-height: 18px;
  color: var(--color-main);
}

.description {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}

.date {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.action {
  font-size: 13px;
  line-height: 16px;
}