@import "/src/animation.scss";

.label {
  color: #575757;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  padding: 16px 0;
}

.name {
  font-size: 15px;
  line-height: 18px;
  color: var(--color-main);
}

.active {
  .name {
    animation: ColorHighLight 1s linear;
    -webkit-animation: ColorHighLight 1s ease-in-out;
    font-weight: 800;
    color: var(--color-accent);
  }
}

.download-btn {
  width: 160px;
  height: 38px;
  border-radius: 14px;
  background-color: var(--color-accent);
  color: white;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}
