.container {
  min-width: 70px;
  overflow: hidden;
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
  }
  .value {
    font-size: 24px;
    line-height: 28px;
    color: var(--color-main);
  }
  .name {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: #a1a1a1;
  }
  .progress__wrapper {
    position: relative;
    margin-top: 20px;
    .white-space {
      background-color: white;
      height: 12px;
      width: 12px;
      border-radius: 6px;
      position: absolute;
      left: -5px;
      top: 0;
    }
    .progress {
      height: 12px;
      width: 100%;
      border-radius: 6px;
    }
  }
  .percent {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #b77de3;
    margin-top: 10px;
  }
  &:nth-child(1) {
    .progress__wrapper .white-space {
      display: none;
    }
  }
}
