.container {
  align-self: flex-start;
  margin-left: 15px;
  margin-top: 15px;
  background-color: var(--color-accent);
  height: 48px;
  padding: 0 15px 0 20px;
  border-radius: 14px;
  .file-name {
    line-height: 20px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    max-width: 200px;
  }
  .file-size {
    font-size: 10px;
    line-height: 12px;
    color: #e3e3e3;
    font-weight: 400;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
