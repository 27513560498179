.container {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: white;
  width: 330px;
  max-height: 800px;
  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-main);
  }
  .btn-read-all {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-accent);
    &:hover {
      cursor: pointer;
    }
  }
  .notification-content__container {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .btn-toggle {
    margin: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-accent);
    &:hover {
      cursor: pointer;
    }
  }
  .btn-load-more {
    min-height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-accent);
    &:hover {
      cursor: pointer;
    }
  }
}

.item__container {
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 15px 0;
  border-bottom: 1px solid #0a0f1a33;
  .dot {
    align-self: flex-start;
    min-width: 8px;
    min-height: 8px;
    border-radius: 4px;
    background-color: var(--color-accent);
    margin-top: 7px;
  }
  .read {
    background-color: unset;
  }
  .content__container {
    flex: 1;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: var(--color-main);
    }
    .time {
      margin-top: 10px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #0a0f1a75;
    }
  }
  .icon {
    margin-left: 10px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }
  &:first-child {
    padding-top: 5px;
  }
  &:hover {
    cursor: pointer;
  }
}
