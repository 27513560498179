.container {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.text-field-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-main);
  line-height: 20px;
}

.upload-field {
  height: 200px;
  background-color: #faf7fd;
  border-radius: 27px;
  border: 1.5px dashed var(--color-accent);
  .upload-description {
    margin-top: 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-accent);
    text-align: center;
  }
  .btn-browse {
    height: 38px;
    width: 141px;
    background-color: var(--color-accent);
    border-radius: 14px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: white;
  }
  &:hover {
    cursor: pointer;
  }
}

.btn-select-date {
  height: 38px;
  border-radius: 14px;
  justify-content: space-between;
  border-color: #0a0f1a75;
  color: var(--color-main);
  text-transform: none;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  &:hover {
    background-color: white;
    border-color: var(--color-main);
  }
  &.empty {
    color: #0a0f1a75;
  }
}

.placeholder {
  opacity: 0.46;
  font-size: 13px;
  font-weight: 400;
  color: var(--color-main);
  line-height: 20px;
}

.calendar {
  border-radius: 20px;
  width: 480px;
  padding-top: 30px;
  padding-bottom: 35px;
  position: relative;
  :global(.MuiPickersCalendarHeader-label) {
    display: none;
  }
  .btn-close {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  :global(.calendar-label) {
    position: absolute;
    left: 50px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-accent);
    text-transform: uppercase;
    top: 52px;
  }
  .btn-cancel {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    color: var(--color-accent);
    border: 1px solid var(--color-accent);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .btn-set-date {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    color: white;
    background-color: var(--color-accent);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}

.input-rate__wrapper {
  background-color: #2c2ed00d;
  border-radius: 14px;
  height: 38px;
  width: 164px;
  margin-top: 10px;
  padding: 0 20px;
  .dot {
    min-width: 8px;
    min-height: 8px;
    background-color: #531b7e;
    border-radius: 4px;
    margin-right: 10px;
  }
  .input-text {
    color: #6c3399;
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
  }
  input {
    outline: none;
    border: none;
    background-color: transparent;
  }
}

.ad-hoc-text {
  line-height: 24px;
  font-size: 15px;
  font-weight: 400;
  color: var(--color-main);
}

.cta {
  width: 340px;
  min-height: 38px;
  border-radius: 14px;
  background-color: var(--color-accent);
  color: white;
  align-self: flex-end;
  margin-top: 52px;
  margin-bottom: 92px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.3;
  &:hover {
    cursor: not-allowed;
  }
}
