.container {
  --color-menu-icon: #ffffffb2;
  &:hover {
    --color-menu-icon: white;
    background-color: var(--color-accent);
  }
}

.active {
  --color-menu-icon: white;
  background-color: var(--color-accent);
}
