.main-button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  .title-button {
    font-size: 16px;
    font-weight: 600;
  }
}

.popup__container {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #808080;
  border-radius: 16px;
  overflow: hidden;
  .popup__item {
    min-width: 200px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    .item__name {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #000000b2;
    }
    .item__icon {
      margin-right: 15px;
    }
    .default {
      color: var(--color-primary-text);
    }
    .destructive {
      color: var(--color-urgent);
    }
    .secondary {
      color: var(--color-secondary-text);
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.popover-transparent-background {
  background-color: transparent;
}
