.container {
  height: 100vh;
  overflow-y: auto;
  position: relative;
  :global(.team-size) {
    margin-left: 25px;
  }
  :global(.key-dates) {
    margin-right: 25px;
  }
  :global(.team-distribution) {
    margin-right: 25px;
  }
}

.calendar {
  border-radius: 20px;
  margin-left: 30px;
  padding: 10px 0;
  max-width: 460px;
  border: 1px solid #ebebeb;
  position: relative;
  :global(.MuiPickersCalendarHeader-label) {
    display: none;
  }
  :global(.calendar-label) {
    position: absolute;
    left: 40px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-accent);
    text-transform: uppercase;
    top: 32px;
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    :global(.team-size) {
      margin-left: 0;
      margin-top: 45px;
    }
    :global(.key-dates) {
      margin-right: 0;
    }
    :global(.team-distribution) {
      margin-right: 0;
    }
  }
  .calendar {
    margin-left: 0;
    margin-top: 35px;
  }
}
