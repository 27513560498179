.container {
  margin-top: 14px;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
  }
  .content-container {
    margin-left: 10px;
    flex: 1;
    .date {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #787878;
    }
    .content {
      font-size: 18px;
      font-weight: 400;
      color: var(--color-main);
      line-height: 24px;
    }
    :global(.MuiOutlinedInput-root) {
      padding: 12px;
      border-radius: 16px;
    }
    :global(.MuiOutlinedInput-input) {
      padding: 0;
      height: 100px;
    }
    .btn-send {
      height: 35px;
      width: 100px;
      justify-content: center;
      background-color: var(--color-accent);
      color: white;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 14px;
      &:hover {
        cursor: pointer;
      }
    }
    .btn-cancel {
      height: 35px;
      width: 100px;
      justify-content: center;
      border: 1px solid var(--color-accent);
      color: var(--color-accent);
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 14px;
      margin-top: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
}
