.container {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.text-field-label {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-main);
  line-height: 20px;
}

.placeholder {
  opacity: 0.46;
  font-size: 13px;
  font-weight: 400;
  color: var(--color-main);
  line-height: 20px;
}
