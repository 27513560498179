@import "/src/animation.scss";

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 19px;
}

.name {
  font-size: 15px;
  line-height: 18px;
  color: var(--color-main);
}

.active {
  .name {
    animation: ColorHighLight 1s linear;
    -webkit-animation: ColorHighLight 1s ease-in-out;
    font-weight: 800;
    color: var(--color-accent);
  }
}

.description {
  color: #8d8d8d;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}

.date {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.english {
  height: 28px;
  min-width: 130px;
  width: fit-content;
  background-color: #2c2ed00d;
  border-radius: 14px;
  .dot {
    margin-left: 20px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 4px;
    background-color: var(--color-accent);
  }
  .english-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--color-accent);
    margin: 0 10px;
  }
}

.next-step {
  min-width: 215px;
  width: fit-content;
  height: 38px;
  border-radius: 14px;
  color: white;
  background-color: var(--color-accent);
  font-weight: 400;
  padding: 0 25px;
  font-size: 13px;
  line-height: 20px;
  align-items: center;
  flex-direction: row;
}
