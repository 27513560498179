@keyframes ColorHighLight {
  0% {
    color: #575757;
  }

  20% {
    color: var(--color-accent);
  }

  40% {
    color: #575757;
  }

  60% {
    color: var(--color-accent);
  }

  80% {
    color: #575757;
  }

  100% {
    color: var(--color-accent);
  }
}
