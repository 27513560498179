.container {
  position: relative;
  width: 600px;
  .btn-close {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .title {
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    color: var(--color-main);
    margin-top: 30px;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #6a6a6a;
    margin-top: 20px;
  }
  .btn-cancel {
    width: 200px;
    height: 38px;
    border-radius: 14px;
    color: var(--color-accent);
    border: 1px solid var(--color-accent);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .btn-confirm {
    width: 200px;
    height: 38px;
    border-radius: 14px;
    color: white;
    background-color: var(--color-accent);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}
