@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

img {
  object-fit: cover;
}

.page-title {
  color: black;
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 40px;
}

.session-label {
  color: var(--color-main);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-flex {
  min-width: 0;
}

:root {
  --color-accent: #6c3399;
  --color-main: #0a0f1a;
  --color-chart-1: #dbc6ec;
  --color-chart-2: #cfb3e5;
  --color-chart-3: #b78cd9;
  --color-chart-4: #9f66cc;
  --color-chart-5: #8840bf;
  --color-chart-6: #6c3399;
  --color-chart-distribution-1: #6c3399;
  --color-chart-distribution-2: #b77de3;
  --color-chart-distribution-3: #dcc0f2;
  --color-chart-distribution-4: #e8d5f6;
}
