.container {
  width: 720px;
  position: relative;
  padding: 0 15px;
  padding-bottom: 40px;

  .btn-close {
    position: absolute;
    top: 12px;
    left: 12px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-top: 50px;
  }
  .name {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    color: var(--color-main);
    margin-top: 25px;
  }
  .sub-text {
    color: #a3a3a3;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
  }
  .file {
    margin-top: 30px;
  }
  .information-container {
    margin-bottom: 40px;
    .label {
      flex: 1;
      color: #787878;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
    }
    .value {
      flex: 1;
      color: var(--color-main);
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .status {
    height: 38px;
    background-color: #0b9607;
    border-radius: 14px;
    color: #ffffff;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 0 30px;
  }
  .progress {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: var(--color-main);
  }
  .detail {
    margin-left: 10px;
    border-radius: 14px;
    background-color: var(--color-accent);
    height: 38px;
    border-radius: 14px;
    color: #ffffff;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 0 18px;
  }
  .comment-container {
    margin-top: 25px;
    padding: 0 35px;
    .comment-label {
      font-size: 14px;
      font-weight: 400;
      color: var(--color-main);
      line-height: 20px;
    }
    :global(.MuiTextField-root) {
      flex: 1;
    }
    :global(.MuiOutlinedInput-root) {
      border-radius: 19px;
    }
    :global(.MuiOutlinedInput-input) {
      padding: 8px 15px;
    }
    .btn-send {
      height: 35px;
      width: 100px;
      justify-content: center;
      background-color: var(--color-accent);
      color: white;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      border-radius: 14px;
      margin-left: 13px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
