.container {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  background-color: #f4f5fd;
  color: var(--color-accent);
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  border-radius: 14px;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: var(--color-accent);
  color: white;
}
