.container {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  .create-user-container {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: var(--color-main);
      margin: 0;
    }
    .label {
      width: 150px;
      color: var(--color-main);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
    .line {
      width: 100%;
      background-color: #ebebeb;
      height: 1px;
      margin-top: 25px;
      margin-bottom: -10px;
    }
    .btn-create {
      height: 38px;
      width: 100%;
      border-radius: 19px;
      background-color: var(--color-accent);
      color: white;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .update-user-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: var(--color-main);
      margin: 0;
    }
    .btn-save {
      height: 38px;
      width: 340px;
      border-radius: 19px;
      background-color: var(--color-accent);
      color: white;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      margin: 40px 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
