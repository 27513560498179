.label {
  color: #575757;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  padding: 16px 0;
}

.name {
  font-size: 15px;
  line-height: 18px;
  color: var(--color-main);
}
