.container {
  background-color: var(--color-main);
  .logo {
    width: 60px;
    height: 60px;
    margin-top: 30px;
    margin-left: 25px;
    border-radius: 30px;
  }
  .user__wrapper {
    margin-top: 40px;
    padding: 0 25px;
    .avatar {
      width: 45px;
      height: 45px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .menu-label {
    font-size: 13px;
    line-height: 16px;
    color: #757575;
    margin-left: 40px;
  }
}

.menu-container {
  display: flex;
  flex-direction: column;
  background-color: #111111;
  padding: 5px;
  .menu-item {
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffffe5;
    width: 190px;
    padding: 0 10px;
    &:hover {
      background-color: #ffffff14;
      cursor: pointer;
    }
    &:last-child {
      border-top: 0.5px solid #343434;
    }
  }
}
