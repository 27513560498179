.container {
  background-image: linear-gradient(to right, #afaaff, #f7ccff);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .body {
    border-radius: 20px;
    padding: 40px 30px 30px 30px;
    box-shadow: 4px 4px 18px 0px #00000033;
    background-color: white;
    width: 590px;
    .logo {
      height: auto;
      width: 233px;
    }
    .title {
      margin: 0;
      margin-top: 24px;
      font-weight: 700;
      font-size: 35px;
      line-height: 41px;
      color: black;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--color-main);
    }
    :global(.MuiTextField-root) {
      margin-top: 0;
      margin-bottom: 0;
      width: 400px;
    }
    .button {
      width: 400px;
      height: 38px;
      border-radius: 14px;
      background-color: var(--color-accent);
      text-transform: none;
      margin-top: 24px;
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 700;
    }
    :global(.MuiLoadingButton-loadingIndicator) {
      color: white;
    }
    .forgot {
      align-self: flex-start;
      width: fit-content;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      color: black;
      text-decoration: none;
    }
  }
}
