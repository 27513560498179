.container {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: var(--color-main);
    margin: 0;
  }
  .field-item {
    padding: 25px 0;
    border-bottom: 0.5px solid #ebebeb;
    .label {
      font-weight: 400;
      line-height: 20px;
      font-size: 14px;
      color: var(--color-main);
      flex: 1;
    }
    :global(.MuiOutlinedInput-input) {
      padding: 11px 15px;
    }
  }
  .cta {
    border-radius: 14px;
    background-color: var(--color-accent);
    color: white;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
  .forgot-pass {
    line-height: 22px;
    color: var(--color-main);
    font-weight: 400;
    font-size: 13px;
    padding: 10px 0;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
